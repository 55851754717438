import React from 'react'

import useCmsInstitutional from '../../hooks/useCmsInstitutional'
import { NpmInstitutionalPage } from '../../local_modules'

import '../../styles/pages/termos.scss'

const Terms = () => {
  const { termsBanners } = useCmsInstitutional()

  return (
    <NpmInstitutionalPage
      title="Termos e Condições Gerais de Uso"
      banners={termsBanners}
    >
      <div className="terms-container">
        <p>
          <strong>Termos de Uso</strong>
        </p>
        <p>
          <strong>Por favor, leia com atenção os termos e condições. </strong>
        </p>
        <p>
          <strong>
            Ao se cadastrar em nosso site você ESTÁ DE ACORDO COM AS CONDIÇÕES E
            TERMOS do website. Note que a recusa destes termos impedirá que você
            faça pedidos de produtos e serviços dentro do website. A compra e
            finalização do seu pedido torna as condições abaixo automaticamente
            aceitas.
          </strong>
        </p>

        <p>
          <strong>CONDIÇÕES GERAIS</strong>
        </p>
        <ul>
          <li>
            1.1 Este TERMO se aplica para regular o uso do serviço oferecido
            pelos sites da Companhia Sulamericana de Distribuição - CSD
            (www.amigao.com) aos USUÁRIOS, seja, possibilitando a escolha, pelo
            USUÁRIO, dos produtos e serviços disponibilizados no website, bem
            como a efetivação do pagamento do preço do produto e/ou serviço de
            acordo com a disponibilidade destes, através dos pedidos de entrega
            e/ou retirada.
          </li>
          <li>
            1.2 O USUÁRIO se declara ciente de que, os produtos e preços
            apresentados pelos sites da Companhia Sulamericana de Distribuição -
            CSD (cidadecancao.com - amigaoonline.com.br -
            saofranciscoonline.com) são válidos exclusivamente para compras
            através do website e serão válidos durante o “dia de hoje”, sujeitos
            a disponibilidade de estoque e alteração de preços sem aviso prévio.
          </li>
          <li>
            1.3 O USUÁRIO se declara ciente de que a atualização de estoque dos
            sites da Companhia Sulamericana de Distribuição - CSD
            (cidadecancao.com - amigaoonline.com.br - saofranciscoonline.com)
            não é feita em tempo real, portanto fazer compras no website não
            garante a quantidade total, ou parcial, dos itens que compõe o
            pedido. Esta confirmação será realizada somente no momento da
            separação dos itens pelo supermercado, sempre sujeitos a variação de
            estoque.
          </li>
          <li>
            1.4 O USUÁRIO se declara que as imagens são meramente ilustrativas.
          </li>
          <li>
            1.5 O USUÁRIO se declara ciente de que as ações/promoções do website
            são destinadas a pessoa física, podendo ser utilizadas em uma compra
            por CPF, não sendo cumulativas.
          </li>
          <li>
            1.6 O USUÁRIO se declara ciente de que o supermercado não entregará
            a compra nos seguintes casos:
            <ul>
              <li>
                a) Problemas com cartão sendo: indisponibilidade de sinal na
                máquina; compra não autorizada e/ou problemas que não efetivem o
                pagamento.
              </li>
              <li>
                b) Não apresentação de documento oficial com foto no ato da
                entrega ou retirada.
              </li>
              <li>c) Pessoa não autorizada a receber/retirar a compra.</li>
            </ul>
          </li>
          <li>
            1.7 O USUÁRIO se declara ciente que o supermercado poderá cancelar o
            pedido nos seguintes casos:
            <ul>
              <li>a) Insucesso no contato com o cliente.</li>
              <li>b) Divergência nos dados inseridos no pedido.</li>
              <li>c) Recusa do USUÁRIO em confirmar os dados.</li>
              <li>d) Pedido de itens em grande volume.</li>
              <li>
                e) O USUÁRIO seja revendedor, como por exemplo, padarias,
                mercearias, minimercados, mercados, açougues, revendedores de
                cestas básicas, etc.
              </li>
              <li>
                f) Não haver recebedor no local indicado, no momento da entrega
                do pedido.
              </li>
              <li>g) O pedido não for retirado no horário agendado.</li>
              <li>
                h) O endereço não for localizado ou for insuficiente para
                efetuar a entrega.
              </li>
            </ul>
          </li>
        </ul>
        <p>
          <strong>CADASTRO</strong>
        </p>
        <ul>
          <li>
            2.1 O USUÁRIO, para utilizar os serviços acima descritos, deverá ter
            capacidade jurídica para atos civis e deverá, necessariamente,
            prestar informações exigidas no CADASTRO.
          </li>
          <li>
            2.1.1 Em caso de informações incorretas, inverídicas ou não
            confirmadas, a Companhia Sulamericana de Distribuição - CSD se
            reserva ao direito de não concluir o cadastramento em curso.
          </li>
          <li>
            2.2 O USUÁRIO se declara ciente que ao efetuar seu cadastro no
            website, automaticamente, já estará cadastrado no programa de
            relacionamento CLUBE+.
          </li>
          <li>
            2.3 Após efetuar o cadastro, O USUÁRIO deverá confirmar seu cadastro
            através do link de confirmação enviado por e-mail.
          </li>
          <li>
            2.4 Após confirmado o cadastro, o USUÁRIO terá acesso aos serviços
            por meio de login e senha.
          </li>
          <li>
            2.4.1 Para compras em nome de Pessoa Jurídica, a Companhia
            Sulamericana de Distribuição - CSD pode exigir documentação.
          </li>
          <li>
            2.5 O USUÁRIO concorda em receber comunicações via e-mail, SMS e
            WhatsApp®, podendo solicitar a remoção de seus vínculos
            promocionais pelo e-mail sac@grupoamigao.com.
          </li>
        </ul>
        <p>
          <strong>OBRIGAÇÕES DO USUÁRIO</strong>
        </p>
        <ul>
          <li>
            3.1 O USUÁRIO deve manter seguras suas credenciais de login e senha.
          </li>
          <li>3.2 O USUÁRIO deve fornecer informações cadastrais verídicas.</li>
          <li>
            3.3 O USUÁRIO deve pagar integralmente os produtos adquiridos.
          </li>
        </ul>
        <p>
          <strong>MODIFICAÇÕES DESTE TERMO</strong>
        </p>
        <ul>
          <li>
            5.1 O presente TERMO DE USO pode ser modificado a qualquer tempo.
          </li>
          <li>
            5.2 As novas condições entrarão em vigor assim que veiculadas no
            website.
          </li>
        </ul>
        <p>
          <strong>Última atualização: 09 de Janeiro de 2024.</strong>
        </p>
      </div>
    </NpmInstitutionalPage>
  )
}

export default Terms
